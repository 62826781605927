import React, { FC } from 'react';
import { ForumContentType } from '@wix/client-search-sdk';

import { ILayoutItemProps } from '../Layout.types';
import { ForumListPostItem, ForumListCommentItem } from './components';
import { classes } from './ForumListItem.st.css';

export interface IForumListItemProps extends ILayoutItemProps {}

export const ForumListItem: FC<IForumListItemProps> = (props) => {
  const { item } = props;

  let ItemComponent: React.FC<IForumListItemProps>;

  if (item.contentType === ForumContentType.Post) {
    ItemComponent = ForumListPostItem;
  } else if (item.contentType === ForumContentType.Comment) {
    ItemComponent = ForumListCommentItem;
  } else {
    return null;
  }

  return (
    <li className={classes.root}>
      <ItemComponent {...props} />
    </li>
  );
};
