import { SearchDocumentType } from '@wix/client-search-sdk';
import { Experiments } from '@wix/yoshi-flow-editor';

import { Spec } from './specs';

export const getTabLabelTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> => {
  return {
    [SearchDocumentType.All]: 'searchResults.tabs.label.all',
    [SearchDocumentType.Pages]: experiments.enabled(Spec.UpdatedSettingsUX)
      ? 'searchResults.tabs.label.otherPages'
      : 'searchResults.tabs.label.pages',
    [SearchDocumentType.Products]: 'searchResults.tabs.label.products',
    [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs',
    [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums',
    [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings',
    [SearchDocumentType.Events]: 'searchResults.tabs.label.events',
    [SearchDocumentType.Programs]: 'searchResults.tabs.label.programs',
    [SearchDocumentType.ProGallery]: 'searchResults.tabs.label.proGallery',
  };
};

export const getTabLabelWithCountTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> => {
  return {
    [SearchDocumentType.All]: 'searchResults.tabs.label.all.withCount',
    [SearchDocumentType.Pages]: experiments.enabled(Spec.UpdatedSettingsUX)
      ? 'searchResults.tabs.label.otherPages.withCount'
      : 'searchResults.tabs.label.pages.withCount',
    [SearchDocumentType.Products]:
      'searchResults.tabs.label.products.withCount',
    [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs.withCount',
    [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums.withCount',
    [SearchDocumentType.Bookings]:
      'searchResults.tabs.label.bookings.withCount',
    [SearchDocumentType.Events]: 'searchResults.tabs.label.events.withCount',
    [SearchDocumentType.Programs]:
      'searchResults.tabs.label.programs.withCount',
    [SearchDocumentType.ProGallery]:
      'searchResults.tabs.label.proGallery.withCount',
  };
};

export const getTabAccessibilityLabelTranslations = (
  experiments: Experiments,
): Record<SearchDocumentType, string> => {
  return {
    [SearchDocumentType.All]: 'searchResults.tabs.label.all.accessibilityLabel',
    [SearchDocumentType.Pages]: experiments.enabled(Spec.UpdatedSettingsUX)
      ? 'searchResults.tabs.label.otherPages.accessibilityLabel'
      : 'searchResults.tabs.label.pages.accessibilityLabel',
    [SearchDocumentType.Products]:
      'searchResults.tabs.label.products.accessibilityLabel',
    [SearchDocumentType.Blogs]:
      'searchResults.tabs.label.blogs.accessibilityLabel',
    [SearchDocumentType.Forums]:
      'searchResults.tabs.label.forums.accessibilityLabel',
    [SearchDocumentType.Bookings]:
      'searchResults.tabs.label.bookings.accessibilityLabel',
    [SearchDocumentType.Events]:
      'searchResults.tabs.label.events.accessibilityLabel',
    [SearchDocumentType.Programs]:
      'searchResults.tabs.label.programs.accessibilityLabel',
    [SearchDocumentType.ProGallery]:
      'searchResults.tabs.label.proGallery.accessibilityLabel',
  };
};
