import React, { FC } from 'react';

import { IListLayoutProps } from '../Layout.types';
import { ListLayout } from '../ListLayout';
import { ForumListItem } from './ForumListItem';

// @TODO
// Missing data/fields from forum:
// * isBestAnswer (comments and replies) and hasBestAnswer (posts);
// * commentingType to be able to handle upvotes and downvotes;
// * Add images to posts and comments;
// Missing from search:
// * Add member info, when implemented globaly by Search;

export const ForumList: FC<IListLayoutProps> = (props) => {
  const renderItem: IListLayoutProps['renderItem'] = (p, key) => {
    return <ForumListItem {...p} key={key} />;
  };

  return <ListLayout {...props} renderItem={renderItem} />;
};
