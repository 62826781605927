import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ISearchProductDocument } from '@wix/client-search-sdk';

import { IGridLayoutProps, OnDocumentClick } from '../Layout.types';
import { GridLayoutItem } from './GridLayoutItem';
import { DocumentClickOrigin } from '../../platform/searchResultsControllerStore';

import { st, classes } from './GridLayout.st.css';

export const GridLayout: React.FC<IGridLayoutProps> = ({
  items,
  label,
  listRef,
  showProductPrice,
  showProductAddToCart,
  showDescription,
  onItemLinkClick,
  onProductAddToCart,
  ...formatProps
}) => {
  const { isMobile, isEditorX } = useEnvironment();

  return (
    <div className={st(classes.container)}>
      <ul
        className={st(classes.root, {
          mobileView: isMobile,
          fluid: isEditorX,
        })}
        data-hook="grid-layout"
        ref={listRef}
        tabIndex={-1}
        role="region list"
        aria-label={label}
      >
        {items.map((item, index) => {
          const handleLinkClick: OnDocumentClick = (
            e,
            clickOrigin: DocumentClickOrigin,
          ) => onItemLinkClick?.(e, item, index, clickOrigin);

          const onProductAddToCartClick: React.MouseEventHandler = () =>
            onProductAddToCart?.(item as ISearchProductDocument);

          return (
            <GridLayoutItem
              {...formatProps}
              item={item}
              key={item.id}
              onLinkClick={handleLinkClick}
              onProductAddToCartClick={onProductAddToCartClick}
              showProductPrice={showProductPrice}
              showProductAddToCart={showProductAddToCart}
              showDescription={showDescription}
            />
          );
        })}
      </ul>
    </div>
  );
};
