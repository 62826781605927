import * as React from 'react';

import { ListLayout } from '../ListLayout';
import { EventListItem } from './EventListItem';
import { IListLayoutProps } from '../Layout.types';

export const EventList: React.FC<IListLayoutProps> = (props) => {
  const renderItem: IListLayoutProps['renderItem'] = (p, key) => {
    return <EventListItem {...p} key={key} />;
  };
  return <ListLayout {...props} renderItem={renderItem} />;
};
