import React, { FC } from 'react';
import { Divider } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useAppSettings } from '../../hooks';
import { IWidgetProps } from '../Widget';
import { ProductFacetsCollections } from './ProductFacetsCollections';
import { ProductFacetsPrice } from './ProductFacetsPrice';
import { isProductCollectionsFacetVisible } from './utils';

import { st, classes } from './ProductFacets.st.css';

interface ProductFacetsProps {
  facets: IWidgetProps['productFacets'];
  onFacetsFilterChange: IWidgetProps['onProductFacetsFilterChange'];
}

export const ProductFacets: FC<ProductFacetsProps> = ({
  facets,
  onFacetsFilterChange,
}) => {
  const { isMobile } = useEnvironment();
  const appSettings = useAppSettings();
  const { collections, filter, minPrice, maxPrice } = facets;
  const { isProductPriceVisible } = appSettings;

  const showCollections = isProductCollectionsFacetVisible(facets);

  return (
    <div className={st(classes.root, { mobileView: isMobile })}>
      {showCollections && (
        <>
          <ProductFacetsCollections
            collections={collections}
            selectedCollections={filter.collections}
            onFacetsFilterChange={onFacetsFilterChange}
          />
          {isProductPriceVisible && <Divider className={classes.divider} />}
        </>
      )}
      {isProductPriceVisible && (
        <ProductFacetsPrice
          minPrice={minPrice}
          maxPrice={maxPrice}
          selectedMinPrice={filter.minPrice}
          selectedMaxPrice={filter.maxPrice}
          onFacetsFilterChange={onFacetsFilterChange}
        />
      )}
    </div>
  );
};
