import { IForumFacetsFilter, IForumFacetsState } from '../../platform/forum';
import {
  ContentTypeOptions,
  ContentTypeOptionsType,
} from './ForumFacets.types';

export const getListTotals = (list: IForumFacetsState['contentTypes'] = []) => {
  return list.reduce((a, b) => a + b.count, 0);
};

export const getActiveType = (
  filter?: IForumFacetsFilter,
): ContentTypeOptionsType => {
  return filter?.contentType ?? ContentTypeOptions.All;
};

export const getActiveCategory = (filter?: IForumFacetsFilter) => {
  return filter?.categoryTitle ?? ContentTypeOptions.All;
};

const DEFAULT_CONTENT_TYPE_COUNT: Record<ContentTypeOptionsType, number> = {
  [ContentTypeOptions.All]: 0,
  [ContentTypeOptions.Post]: 0,
  [ContentTypeOptions.Comment]: 0,
};

export const getTypeCounts = (
  contentTypes: IForumFacetsState['contentTypes'],
): Record<ContentTypeOptionsType, number> => {
  const typeCounts = Object.values(ContentTypeOptions).reduce((acc, key) => {
    if (key === ContentTypeOptions.All) {
      return { ...acc, [key]: getListTotals(contentTypes) };
    }

    return {
      ...acc,
      [key]: contentTypes.find(({ value }) => value === key)?.count || 0,
    };
  }, DEFAULT_CONTENT_TYPE_COUNT);

  return typeCounts;
};
